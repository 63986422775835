<template>
    <v-container fluid grid-list-md class="padless">
        <v-layout wrap class="hidden-sm-and-down">
            <v-flex d-flex xs12 justify-center align-center class="page-top-margin">
                &nbsp;
            </v-flex>
        </v-layout>
        
      <template v-for="(item, i) in virtualTourList">
        <v-layout wrap :key="i">
            <v-flex v-if="item.walkthroughVideo.title" d-flex xs12 align-center mx-5>
              <h2 class="primary--text text--lighten-1 ml-5" :class="{'h2-sm': $vuetify.breakpoint.xs}">
                {{ item.walkthroughVideo.title }}
              </h2>
            </v-flex>
            
            <v-flex v-if="item.walkthroughVideo.url" d-flex xs12 justify-center align-center style="position: relative; padding-top: 56.25%;">
                <iframe :src="item.walkthroughVideo.url" frameborder=0 allowfullscreen style="position:absolute; top:0; left:0; width:100%; height:100%;"></iframe>
            </v-flex>

            <v-flex v-if="item.virtualTour.title" d-flex xs12 align-center mx-5>
              <h2 class="primary--text text--lighten-1 ml-5" :class="{'h2-sm': $vuetify.breakpoint.xs}">
                {{ item.virtualTour.title }}
              </h2>
            </v-flex>
            
            <v-flex v-if="item.virtualTour.url" d-flex xs12 justify-center align-center mb-1 style="position: relative; padding-top: 56.25%;">
                <iframe :src="item.virtualTour.url" frameborder=0 allowfullscreen allow='vr' style="position:absolute; top:0; left:0; width:100%; height:100%;"></iframe>
            </v-flex>
        </v-layout>
      </template>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "VirtualTour",

  data() {
    return {
      marginTop: 53
    };
  },

  computed: {
    ...mapGetters(["virtualTourList"])
  },

  methods: {
    ...mapActions(["getVirtualTourList"])
  },

  created() {
    this.getVirtualTourList();
  }
};
</script>
